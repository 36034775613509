export { createOrUpdateDataSourceWithAction } from "ce/sagas/DatasourcesSagas";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
  ReduxFormActionTypes,
} from "ee/constants/ReduxActionConstants";
import type {
  ReduxAction,
  ReduxActionWithCallbacks,
} from "actions/ReduxActionTypes";
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  addAndFetchDatasourceStructureSaga,
  addMockDbToDatasources,
  changeDatasourceSaga,
  createDatasourceFromFormSaga,
  datasourceDiscardActionSaga,
  deleteDatasourceSaga,
  executeDatasourceQuerySaga,
  fetchDatasourcesSaga,
  fetchDatasourceStructureSaga,
  fetchGsheetColumns,
  fetchGsheetSheets,
  fetchGsheetSpreadhsheets,
  fetchMockDatasourcesSaga,
  filePickerActionCallbackSaga,
  formValueChangeSaga,
  getOAuthAccessTokenSaga,
  handleDatasourceNameChangeFailureSaga,
  handleFetchDatasourceStructureOnLoad,
  initializeFormWithDefaults,
  loadFilePickerSaga,
  redirectAuthorizationCodeSaga,
  refreshDatasourceStructure,
  setDatasourceViewModeSaga as CE_setDatasourceViewModeSaga,
  storeAsDatasourceSaga,
  switchDatasourceSaga,
  testDatasourceSaga,
  updateDatasourceAuthStateSaga,
  updateDatasourceNameSaga,
  updateDatasourceSaga as CE_updateDatasourceSaga,
  updateDatasourceSuccessSaga,
  createTempDatasourceFromFormSaga as CE_createTempDatasourceFromFormSaga,
} from "ce/sagas/DatasourcesSagas";
import type { CreateDatasourceConfig } from "api/DatasourcesApi";
import {
  AuthenticationType,
  ToastMessageType,
  type Datasource,
  type ExternalSaasDSAuthentication,
} from "entities/Datasource";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import type { WorkspaceToken } from "ee/constants/workspaceConstants";
import {
  getCurrentEnvironmentDetails,
  getCurrentEnvironmentId,
} from "ee/selectors/environmentSelectors";
import {
  getDatasource,
  getDatasources,
  getPlugin,
} from "ce/selectors/entitiesSelector";
import { type Plugin, PluginType } from "entities/Plugin";
import { paragon, type IntegrationInstallEvent } from "@useparagon/connect";
import { toast } from "@appsmith/ads";
import {
  DATASOURCE_NAME_DEFAULT_PREFIX,
  TEMP_DATASOURCE_ID,
} from "constants/Datasource";
import WorkspaceApi, {
  type FetchWorkspaceTokenResponse,
} from "ee/api/WorkspaceApi";
import { getNextEntityName } from "utils/AppsmithUtils";
import { get, set } from "lodash";
import { objectKeys } from "@appsmith/utils";
import { getCurrentUser } from "selectors/usersSelectors";
import type { User } from "constants/userConstants";
import { datasourcesEditorIdURL } from "ee/RouteBuilder";
import history, { NavigationMethod } from "utils/history";

// Sample change
function* handleParagonSuccess(
  event: IntegrationInstallEvent,
  plugin: Plugin,
  currentEnvId: string,
) {
  const dsList: Datasource[] = yield select(getDatasources);
  const datasourceName = getNextEntityName(
    DATASOURCE_NAME_DEFAULT_PREFIX,
    dsList.map((el: Datasource) => el.name),
  );

  const { credential, credentialId, integrationId, integrationType } =
    event || {};

  const { providerData = {} } = credential || {};
  const providerDataArray: { key: string; value: string | boolean | number }[] =
    [];

  objectKeys(providerData).forEach((key) => {
    providerDataArray.push({
      key,
      value: providerData[key],
    });
  });

  const reduxActionPayload: ReduxActionWithCallbacks<
    CreateDatasourceConfig,
    unknown,
    unknown
  > = {
    type: ReduxActionTypes.CREATE_DATASOURCE_FROM_FORM_INIT,
    payload: {
      name: datasourceName,
      type: PluginType.EXTERNAL_SAAS,
      pluginId: plugin?.id,
      datasourceStorages: {
        [currentEnvId]: {
          environmentId: currentEnvId,
          datasourceId: TEMP_DATASOURCE_ID,
          isValid: false,
          datasourceConfiguration: {
            url: "",
            authentication: {
              integrationId,
              credentialId,
              integrationType,
              authenticationType:
                AuthenticationType.EXTERNAL_SAAS_AUTHENTICATION,
              providerData: providerDataArray,
            },
          },
          toastMessage: ToastMessageType.EMPTY_TOAST_MESSAGE,
        },
      },
    },
  };

  yield call(createDatasourceFromFormSaga, reduxActionPayload);
}

export function* createTempDatasourceFromFormSaga(
  actionPayload: ReduxAction<CreateDatasourceConfig | Datasource>,
) {
  yield put({
    type: ReduxActionTypes.CREATE_DATASOURCE_INIT,
  });
  const workspaceId: string = yield select(getCurrentWorkspaceId);
  const currentEnvId: string = yield select(getCurrentEnvironmentId);
  const plugin: Plugin = yield select(
    getPlugin,
    actionPayload?.payload?.pluginId,
  );
  const currentUser: User = yield select(getCurrentUser);

  if (plugin?.type === PluginType.EXTERNAL_SAAS) {
    try {
      const responseToken: FetchWorkspaceTokenResponse = yield call(
        WorkspaceApi.fetchWorkspaceToken,
        {
          workspaceId: workspaceId,
        },
      );

      const currentWorkspaceToken: WorkspaceToken = responseToken.data;

      if (!currentWorkspaceToken.token || !currentWorkspaceToken.projectId) {
        toast.show("Project Id and Token not set", { kind: "error" });

        return;
      }

      yield call(
        paragon.authenticate,
        currentWorkspaceToken.projectId,
        currentWorkspaceToken.token,
        {
          metadata: {
            Name: currentUser.name,
            Email: currentUser.email,
          },
        },
      );

      yield put({
        type: ReduxActionTypes.CREATE_DATASOURCE_FROM_FORM_TOGGLE_LOADING,
        payload: {
          loading: false,
        },
      });

      // Convert Promise-based integration installation to saga using Promise wrapper
      const event: IntegrationInstallEvent = yield call(
        async () =>
          new Promise((resolve, reject) => {
            paragon.installIntegration(plugin.pluginName!, {
              allowMultipleCredentials: true,
              onSuccess: resolve,
              onError: reject,
            });
          }),
      );

      // Now we can call our success handler
      yield call(handleParagonSuccess, event, plugin, currentEnvId);
    } catch (error) {
      yield put({
        type: ReduxActionErrorTypes.CREATE_DATASOURCE_ERROR,
        payload: { error },
      });
    }
  } else {
    yield call(CE_createTempDatasourceFromFormSaga, actionPayload);
  }
}

// Sample change
function* handleParagonUpdateSuccess(
  event: IntegrationInstallEvent,
  actionPayload: ReduxActionWithCallbacks<
    Datasource & { isInsideReconnectModal: boolean; currEditingEnvId?: string },
    unknown,
    unknown
  >,
  currentEnvId: string,
) {
  const { credential, credentialId, integrationId, integrationType } =
    event || {};

  const { providerData = {} } = credential || {};
  const providerDataArray: { key: string; value: string | boolean | number }[] =
    [];

  objectKeys(providerData).forEach((key) => {
    providerDataArray.push({
      key,
      value: providerData[key],
    });
  });
  set(
    actionPayload.payload,
    `datasourceStorages[${currentEnvId}].datasourceConfiguration.authentication.providerData`,
    providerDataArray,
  );

  set(
    actionPayload.payload,
    `datasourceStorages[${currentEnvId}].datasourceConfiguration.authentication.credentialId`,
    credentialId,
  );

  set(
    actionPayload.payload,
    `datasourceStorages[${currentEnvId}].datasourceConfiguration.authentication.integrationId`,
    integrationId,
  );

  set(
    actionPayload.payload,
    `datasourceStorages[${currentEnvId}].datasourceConfiguration.authentication.integrationType`,
    integrationType,
  );

  set(
    actionPayload.payload,
    `datasourceStorages[${currentEnvId}].datasourceConfiguration.authentication.authenticationType`,
    AuthenticationType.EXTERNAL_SAAS_AUTHENTICATION,
  );

  yield call(CE_updateDatasourceSaga, actionPayload);
}

export function* updateDatasourceSaga(
  actionPayload: ReduxActionWithCallbacks<
    Datasource & { isInsideReconnectModal: boolean; currEditingEnvId?: string },
    unknown,
    unknown
  >,
) {
  const currentEnvDetails: { editingId: string; name: string } = yield select(
    getCurrentEnvironmentDetails,
  );
  const currentEnvId =
    actionPayload.payload?.currEditingEnvId || currentEnvDetails.editingId;

  const workspaceId: string = yield select(getCurrentWorkspaceId);
  const plugin: Plugin = yield select(
    getPlugin,
    actionPayload?.payload?.pluginId,
  );

  const currentUser: User = yield select(getCurrentUser);

  const authenticationPayload = get(
    actionPayload.payload,
    `datasourceStorages[${currentEnvId}].datasourceConfiguration.authentication`,
  ) as ExternalSaasDSAuthentication;

  if (plugin?.type === PluginType.EXTERNAL_SAAS) {
    try {
      const responseToken: FetchWorkspaceTokenResponse = yield call(
        WorkspaceApi.fetchWorkspaceToken,
        {
          workspaceId: workspaceId,
        },
      );

      const currentWorkspaceToken: WorkspaceToken = responseToken.data;

      if (!currentWorkspaceToken.token || !currentWorkspaceToken.projectId) {
        toast.show("Project Id and Token not set", { kind: "error" });

        return;
      }

      yield call(
        paragon.authenticate,
        currentWorkspaceToken.projectId,
        currentWorkspaceToken.token,
        {
          metadata: {
            Name: currentUser.name,
            Email: currentUser.email,
          },
        },
      );

      yield put({
        type: ReduxActionTypes.CREATE_DATASOURCE_FROM_FORM_TOGGLE_LOADING,
        payload: {
          loading: false,
        },
      });
      // Convert Promise-based integration installation to saga using Promise wrapper
      const event: IntegrationInstallEvent = yield call(
        async () =>
          new Promise((resolve, reject) => {
            paragon.installIntegration(plugin.pluginName!, {
              allowMultipleCredentials: true,
              // @ts-expect-error  selectedCredentialId & onClose function is not present in type
              selectedCredentialId: authenticationPayload?.credentialId,
              onSuccess: resolve,
              onError: reject,
            });
          }),
      );

      // Now we can call our success handler
      yield call(
        handleParagonUpdateSuccess,
        event,
        actionPayload,
        currentEnvId,
      );
    } catch (error) {
      yield put({
        type: ReduxActionErrorTypes.UPDATE_DATASOURCE_ERROR,
        payload: { error },
      });
    }
  } else {
    yield call(CE_updateDatasourceSaga, actionPayload);
  }
}

export function* setDatasourceViewModeSaga(
  action: ReduxAction<{ datasourceId: string; viewMode: boolean }>,
) {
  const datasource: Datasource | undefined = yield select(
    getDatasource,
    action.payload.datasourceId,
  );

  if (datasource) {
    const plugin: Plugin = yield select(getPlugin, datasource.pluginId);

    if (plugin.type === PluginType.EXTERNAL_SAAS) {
      history.replace(
        datasourcesEditorIdURL({
          datasourceId: datasource.id,
        }),
        {
          invokedBy: NavigationMethod.AppNavigation,
        },
      );
    }
  }

  yield call(CE_setDatasourceViewModeSaga, action);
}

export function* watchDatasourcesSagas() {
  yield all([
    takeEvery(ReduxActionTypes.FETCH_DATASOURCES_INIT, fetchDatasourcesSaga),
    takeEvery(
      ReduxActionTypes.FETCH_MOCK_DATASOURCES_INIT,
      fetchMockDatasourcesSaga,
    ),
    takeEvery(
      ReduxActionTypes.ADD_MOCK_DATASOURCES_INIT,
      addMockDbToDatasources,
    ),
    takeEvery(
      ReduxActionTypes.CREATE_DATASOURCE_FROM_FORM_INIT,
      createDatasourceFromFormSaga,
    ),
    takeEvery(
      ReduxActionTypes.CREATE_TEMP_DATASOURCE_FROM_FORM_SUCCESS,
      createTempDatasourceFromFormSaga,
    ),
    takeEvery(ReduxActionTypes.UPDATE_DATASOURCE_INIT, updateDatasourceSaga),
    takeEvery(
      ReduxActionTypes.UPDATE_DATASOURCE_NAME,
      updateDatasourceNameSaga,
    ),
    takeEvery(
      ReduxActionErrorTypes.UPDATE_DATASOURCE_NAME_ERROR,
      handleDatasourceNameChangeFailureSaga,
    ),
    takeEvery(ReduxActionTypes.TEST_DATASOURCE_INIT, testDatasourceSaga),
    takeEvery(ReduxActionTypes.DELETE_DATASOURCE_INIT, deleteDatasourceSaga),
    takeEvery(ReduxActionTypes.CHANGE_DATASOURCE, changeDatasourceSaga),
    takeLatest(ReduxActionTypes.SWITCH_DATASOURCE, switchDatasourceSaga),
    takeEvery(ReduxActionTypes.STORE_AS_DATASOURCE_INIT, storeAsDatasourceSaga),
    takeEvery(
      ReduxActionTypes.UPDATE_DATASOURCE_SUCCESS,
      updateDatasourceSuccessSaga,
    ),
    takeEvery(
      ReduxActionTypes.REDIRECT_AUTHORIZATION_CODE,
      redirectAuthorizationCodeSaga,
    ),
    takeEvery(ReduxActionTypes.GET_OAUTH_ACCESS_TOKEN, getOAuthAccessTokenSaga),
    takeEvery(
      ReduxActionTypes.FETCH_DATASOURCE_STRUCTURE_INIT,
      fetchDatasourceStructureSaga,
    ),
    takeEvery(
      ReduxActionTypes.REFRESH_DATASOURCE_STRUCTURE_INIT,
      refreshDatasourceStructure,
    ),
    takeEvery(
      ReduxActionTypes.EXECUTE_DATASOURCE_QUERY_INIT,
      executeDatasourceQuerySaga,
    ),
    takeEvery(
      ReduxActionTypes.INITIALIZE_DATASOURCE_FORM_WITH_DEFAULTS,
      initializeFormWithDefaults,
    ),
    // Intercepting the redux-form change actionType to update drafts and track change history
    takeEvery(ReduxFormActionTypes.VALUE_CHANGE, formValueChangeSaga),
    takeEvery(ReduxFormActionTypes.ARRAY_PUSH, formValueChangeSaga),
    takeEvery(ReduxFormActionTypes.ARRAY_REMOVE, formValueChangeSaga),
    takeEvery(
      ReduxActionTypes.FILE_PICKER_CALLBACK_ACTION,
      filePickerActionCallbackSaga,
    ),
    takeLatest(
      ReduxActionTypes.FETCH_GSHEET_SPREADSHEETS,
      fetchGsheetSpreadhsheets,
    ),
    takeLatest(ReduxActionTypes.FETCH_GSHEET_SHEETS, fetchGsheetSheets),
    takeLatest(ReduxActionTypes.FETCH_GSHEET_COLUMNS, fetchGsheetColumns),
    takeEvery(ReduxActionTypes.LOAD_FILE_PICKER_ACTION, loadFilePickerSaga),
    takeEvery(
      ReduxActionTypes.UPDATE_DATASOURCE_AUTH_STATE,
      updateDatasourceAuthStateSaga,
    ),
    takeEvery(
      ReduxActionTypes.DATASOURCE_DISCARD_ACTION,
      datasourceDiscardActionSaga,
    ),
    takeEvery(
      ReduxActionTypes.ADD_AND_FETCH_MOCK_DATASOURCE_STRUCTURE_INIT,
      addAndFetchDatasourceStructureSaga,
    ),
    takeEvery(
      ReduxActionTypes.FETCH_DATASOURCES_SUCCESS,
      handleFetchDatasourceStructureOnLoad,
    ),
    takeEvery(
      ReduxActionTypes.SOFT_REFRESH_DATASOURCE_STRUCTURE,
      handleFetchDatasourceStructureOnLoad,
    ),
    takeEvery(
      ReduxActionTypes.SET_DATASOURCE_EDITOR_MODE,
      setDatasourceViewModeSaga,
    ),
  ]);
}
